.carousel-item {
  height: 32rem; }

.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem; }

.carousel-videobg:before {
  content: '';
  background: black;
  background: linear-gradient(90deg, transparent 0%, rgba(26, 18, 16, 0.48) 60%, #1a1210 80%, #1a1210 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.mainpageCarousel .carousel-item {
  background: #1d1c1d; }

.videopolygon {
  clip-path: polygon(0 14%, 100% 0%, 100% 100%, 0 85%);
  height: 720px;
  background: #dbdbdb; }

@media (max-width: 767px) {
  .videopolygon {
    height: auto; }
  .homebannerVideo {
    width: 100%; } }

.bg-light-new {
  background: #EFF2F9; }

.about-section.aboutnew-section {
  background: #fff; }

.contact-section.cta-bg.newcta-bg {
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%); }
